import React from "react";

let shownOnce = false;

const HiddenPhoneNoButton = ({ phoneNumber }: { phoneNumber: string }) => {
	const [show, setShow] = React.useState(shownOnce);
	const handleHiddenPhoneNumberClick = () => {
		if (shownOnce) return;
		shownOnce = true;
		setShow(true);

		(window as any).gtag?.("event", "phone_call");
		(window as any).fbq?.("track", "Purchase");
	};

	const convertPhoneNumberForCall = (input: string): string => {
		// Remove all spaces from the input string
		const cleanedInput = input.replace(/\s+/g, "");
		// Prefix with 'tel:' and return the result
		return `tel:${cleanedInput}`;
	};

	return (
		<button onClick={handleHiddenPhoneNumberClick} className="act_btn_small" style={{ marginLeft: "4px" }}>
			{show ? (
				<a
					href={convertPhoneNumberForCall(phoneNumber)}
					style={{ textDecoration: "none", color: "inherit", cursor: "pointer", fontSize: "12px" }}
				>
					{phoneNumber}
				</a>
			) : (
				<div style={{ display: "flex", flexDirection: "column" }}>
					<span style={{ fontSize: "12px", marginBottom: "2px" }}>Atidengti numerį</span>
					<span style={{ fontSize: "12px" }}>+370 XXX XXXX</span>
				</div>
			)}
		</button>
	);
};

export default HiddenPhoneNoButton;
