import outdatedBrowser from "outdated-browser-rework";

outdatedBrowser({
	browserSupport: {
		Chrome: 57, // Includes Chrome for mobile devices
		Edge: 39,
		Safari: 10,
		"Mobile Safari": 10,
		Firefox: 50,
		Opera: 50,
		Vivaldi: 1,
		// You could specify minor version too for those browsers that need it.
		Yandex: { major: 17, minor: 10 },
		// You could specify a version here if you still support IE in 2017.
		// You could also instead seriously consider what you're doing with your time and budget
		IE: false,
	},
	requireChromeOnAndroid: false,
	isUnknownBrowserOK: false,
	messages: {
		en: {
			outOfDate: "Jūsų naršykle yra pasenusi!",
			unsupported: "Jūsų naršykle yra pasenusi!",
			update: {
				web:
					"Jūsų naršyklė yra pasenusi. Prašome ir smarkiai rekomenduojame atnaujinti savo naršyklę norint taisyklingai matyti šį ir kitus tinklapius!",
				googlePlay:
					"Jūsų naršyklė yra pasenusi. Prašome ir smarkiai rekomenduojame atnaujinti savo naršyklę norint taisyklingai matyti šį ir kitus tinklapius!",
				appStore:
					"Jūsų naršyklė yra pasenusi. Prašome ir smarkiai rekomenduojame atnaujinti savo naršyklę norint taisyklingai matyti šį ir kitus tinklapius!",
			},
			// You can set the URL to null if you do not want a clickable link or provide
			// your own markup in the `update.web` message.
			url: "http://outdatedbrowser.com/",
			callToAction: "Atnaujinti mano naršyklę dabar!",
			close: "Uždaryti",
		},
	},
});
