import React from "react";
import { Link } from "react-router-dom";
import { logo, logoWhite } from "@webImgs";
import HamburgerBtn from "./HamburgerBtn";
import { useLocalStore, observer } from "mobx-react-lite";
import { PageUrl } from "@shared/types/common";
import SvgInlined from "./SvgInlined";

interface P {
	mod?: "green";
}

const Header: React.FC<P> = observer((p) => {
	const LS = useLocalStore(() => ({
		active: false,
		onClick() {
			this.active = !this.active;
		},
		linkClick(link: string) {
			this.active = false;
		},
	}));
	const isGreen = p.mod === "green";
	const classes = [`header`];
	if (isGreen) classes.push("header--green");
	if (LS.active) classes.push("header--mob-active");
	return (
		<header className={classes.join(" ")}>
			<Link to="/" className="header__logo-wrap">
				<SvgInlined className="header__logo" title="Dzukijos Valdos" svgText={isGreen ? logoWhite : logo} />
				{isGreen && <span>DZŪKIJOS VALDOS</span>}
			</Link>
			<div className="header__links">
				<div className="header__nav-logo-wrap">
					<SvgInlined className="header__nav-logo" title="Dzukijos Valdos" svgText={logoWhite} />
					<span className="header__logo-text">DZŪKIJOS VALDOS</span>
				</div>
				<Link onClick={() => LS.linkClick("/")} to="/">
					<i className="header__icon icon-house1"></i>Pagrindinis
				</Link>
				<Link onClick={() => LS.linkClick(PageUrl.NT_PASLAUGOS)} to={PageUrl.NT_PASLAUGOS}>
					<i className="header__icon icon-geodesy"></i>NT Paslaugos
				</Link>
				<Link onClick={() => LS.linkClick(PageUrl.NT_SKELBIMAI)} to={PageUrl.NT_SKELBIMAI}>
					<i className="header__icon icon-search1"></i>NT Skelbimai
				</Link>
				<Link onClick={() => LS.linkClick(PageUrl.KAINOS)} to={PageUrl.KAINOS}>
					<i className="header__icon icon-euro-sign2" style={{ marginRight: 20 }}></i>Matavimų kainos
				</Link>
				<Link onClick={() => LS.linkClick(PageUrl.APIE_MUS)} to={PageUrl.APIE_MUS}>
					<i className="header__icon icon-star1"></i>Apie mus
				</Link>
				<Link onClick={() => LS.linkClick(PageUrl.KONTAKTAI)} id="special-btn" to={PageUrl.KONTAKTAI}>
					<i className="header__icon icon-phone1"></i>Kontaktai
				</Link>
			</div>
			<div className="hambtn">
				<HamburgerBtn active={LS.active} onClick={LS.onClick} />
			</div>
		</header>
	);
});
export default Header;
