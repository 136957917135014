import React from "react";
import { statProps, StatProps, AllObjPropsC } from "@shared/types";
import _ from "lodash";

import { useLocalStore, observer } from "mobx-react-lite";
import PhotosModal from "./PhotosModal";
import adressLineGen from "@client/common/util/adressLineGen";
import Headline, { Heading } from "../../common_comps/Headline";
import { enumsTranslate } from "@client/common/util/valueTranslate";
import perPriceString from "@client/common/util/perPriceString";
import HiddenPhoneNoButton from "@client/web/src/common_comps/HiddenPhoneNo";

function filterStats(obj: AllObjPropsC): Required<StatProps> {
	const filteredStatProps = _.pickBy(obj, (val, key) => statProps.includes(key) && val);
	return filteredStatProps as Required<StatProps>;
}

const labels: Record<
	keyof StatProps,
	{
		statLabel: string;
		valueLabel: string | null;
	}
> = {
	houseType: {
		statLabel: "Namo tipas",
		valueLabel: null,
	},
	security: {
		statLabel: "Apsauga",
		valueLabel: null,
	},
	additionalLodgings: {
		statLabel: "Papildomi pastatai",
		valueLabel: null,
	},
	garageFeatures: {
		statLabel: "Garažo ypatybės",
		valueLabel: null,
	},
	buildingPurpose: {
		statLabel: "Patalpų paskirtis",
		valueLabel: null,
	},
	garageType: {
		statLabel: "Garažo tipas",
		valueLabel: null,
	},
	insideSpace: {
		statLabel: "Bendras plotas",
		valueLabel: "m²",
	},
	outsideSpace: {
		statLabel: "Sklypo plotas",
		valueLabel: "a",
	},
	roomCount: {
		statLabel: "Kambarių sk.",
		valueLabel: null,
	},
	floor: {
		statLabel: "Aukštas",
		valueLabel: null,
	},
	totalFloors: {
		statLabel: "Aukštų sk.",
		valueLabel: null,
	},
	buildingYear: {
		statLabel: "Statybos metai",
		valueLabel: null,
	},
	buildingType: {
		statLabel: "Pastato tipas",
		valueLabel: null,
	},
	heatingType: {
		statLabel: "Šildymas",
		valueLabel: null,
	},
	waterType: {
		statLabel: "Vanduo",
		valueLabel: null,
	},
	installationType: {
		statLabel: "Įrengimas",
		valueLabel: null,
	},
	landPurpose: {
		statLabel: "Sklypo paskirtis",
		valueLabel: null,
	},
	landFeatures: {
		statLabel: "Žemės ypatybės",
		valueLabel: null,
	},
	carFitCount: {
		statLabel: "Telpa mašinų",
		valueLabel: null,
	},
	condition: {
		statLabel: "Būklė",
		valueLabel: null,
	},
	buildingFeatures: {
		statLabel: "Pastato ypatybės",
		valueLabel: null,
	},
};

interface StatP {
	name: keyof StatProps;
	values: string | number | (string | number)[];
}
const Stat: React.FC<StatP> = (p) => {
	const statLabel = labels[p.name].statLabel;
	let valueLabel = labels[p.name].valueLabel;

	const translatedVals = Array.isArray(p.values) ? p.values.map((v) => enumsTranslate(p.name, v)) : [p.values];
	return (
		<div className="objp__stat">
			<span className="text--b">{statLabel}: </span>
			<span>
				{translatedVals.join(", ")} {valueLabel || ""}
			</span>
		</div>
	);
};

interface ObjP {
	obj: AllObjPropsC;
}
const Obj: React.FC<ObjP> = observer((p) => {
	const { obj } = p;
	const statsForTable = filterStats(obj);
	const LS = useLocalStore(() => ({
		modalOpen: false,
		photoIndex: 0,
		imgClick(index: number) {
			this.photoIndex = index;
			this.modalOpen = true;
		},
	}));
	const otherPhotosC: string[] = [];
	if (obj.imgUrls?.length < 5) {
		otherPhotosC.push("hide");
	}
	var sepPrice = obj.price.toLocaleString("fr-FR");

	return (
		<div className="objp side-padding">
			<Headline hType={"h1"} text={obj.title} type={"left"} />
			<div className="objp__after-head">
				<span className="objp__seen-by">
					Skelbimą peržiūrėjo (iš viso):{" "}
					<span className="text--sb">{Number(obj.views_counter) + Number(obj.old_views_counter) || 0}</span>
				</span>
				<span className="objp__pid">Skelbimo ID: {obj.publicId}</span>
			</div>
			<div className="objp__photo-wrapper objp__photo-wrapper--main">
				<div className="objp__img-wrap objp__main-img-wrap" onClick={() => LS.imgClick(0)}>
					<img
						alt={obj.title + ". Nuotrauka nr. 1"}
						title={obj.title + ". Nuotrauka nr. 1"}
						className="objp__img"
						src={obj.imgUrls[0]}
					/>
				</div>
				<div className={`objp__other-photos ${otherPhotosC.join(" ")}`}>
					{obj.imgUrls.slice(1, 5).map((url, i) => (
						<div
							key={url}
							className="objp__img-wrap objp__img-wrap--small"
							onClick={() => LS.imgClick(i + 1)}
						>
							<img
								alt={obj.title + `. Nuotrauka nr. ${i + 2}`}
								title={obj.title + `. Nuotrauka nr. ${i + 2}`}
								className="objp__img objp__img--small"
								src={url}
							/>
						</div>
					))}
					<div className="objp__more-photos">{obj.imgUrls.length - 5}</div>
				</div>
			</div>
			<div className="objp__col-wrap">
				<div className="objp__left-col">
					<div
						className="objp__descr-wrap tui-editor-contents"
						dangerouslySetInnerHTML={{ __html: obj.description || "" }}
					></div>
				</div>
				<div className="objp__right-col">
					<div className="objp__contact-box-1">
						<Heading type={"h3"} green lessMargin>
							Susisiekti su pardavėju
						</Heading>
						<div className="objp__contact-text">
							<div className="objp__contact-line">
								<span className="text--sb"> Pardavėjas: </span>
								<span>UAB Dzūkijos valdos</span>
							</div>
							<div className="objp__contact-line">
								<span className="text--sb">Mob. tel. nr: </span>
								<HiddenPhoneNoButton phoneNumber="+370 611 19038" />
							</div>
							<div className="objp__contact-line">
								<span className="text--sb">Ofiso tel. nr: </span>
								<HiddenPhoneNoButton phoneNumber="+370 315 51525" />
							</div>
							<div className="objp__contact-line">
								<span className="text--sb">El. paštas: </span>
								<a className="objp__contact-clickable" href="mailto: nt@dzukuvaldos.lt">
									nt@dzukuvaldos.lt
								</a>
							</div>
							{/* <div className="objp__contact-line">
								Dalintis:
								<button
									type="button"
									className="act_btn act_btn--square act_btn--small act_btn--share "
								>
									<i className="icon-share2" />
								</button>
							</div> */}
						</div>
					</div>
					<div className="objp__stats-wrap">
						<div className="objp__price-wrap">
							<span className="objp__price">Kaina: {sepPrice} €</span>
							<span className="objp__price-per">{perPriceString(obj)}</span>
						</div>
						<div className="objp__stats">
							{_.map(statsForTable, (val, key) => {
								if (!val || (Array.isArray(val) && val.length === 0)) return;
								if (key === "street" && obj.streetNr) val += ` ${obj.streetNr}`;
								return <Stat key={key + val} name={key as keyof StatProps} values={val} />;
							})}
						</div>
					</div>
				</div>
			</div>
			<Heading type={"h2"}>{adressLineGen(obj)}</Heading>
			<div className="objp__iframe-wrap">
				<iframe className="objp__iframe" src={obj.mapUrl} />
			</div>
			<PhotosModal modalInfo={LS} images={obj.imgUrls.map((url) => ({ source: url, caption: "" }))} />
		</div>
	);
});
export default Obj;
